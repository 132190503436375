'use client';
import { ITEMS_PER_PAGE } from '@/constants/Constants';
import { Vacancy } from '@/types';
import { Pagination, Spin } from 'antd';
import { usePathname, useRouter } from 'next/navigation';
import React, { useEffect, useMemo, useState } from 'react';
import VacancyCard from '../VacancyCard/VacancyCard';

export type ListVacanciesT = {
  VacanciesnFromDB: Vacancy[];
  ammountOfVacancies: any;
  currentPage?: number;
  currentLocation?: string[];
};

const ListVacancies: React.FC<ListVacanciesT> = ({
  VacanciesnFromDB,
  currentPage,
  currentLocation,
  ammountOfVacancies
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const router = useRouter();
  const pathname = usePathname();

  useEffect(() => {
    setIsLoading(!VacanciesnFromDB || VacanciesnFromDB.length === 0);
  }, [VacanciesnFromDB]);

  console.log(`currentLocation`, currentLocation);
  const handlePageChange = async (page: number) => {
    console.log(`Fetching data for page ${page}`);

    // Check if currentLocation is valid (not empty)
    const isLocationValid = currentLocation?.length && currentLocation?.length > 1;

    router.push(
      isLocationValid
        ? `/vacanciesPage/${page}?location=${currentLocation.join(',')}`
        : `/vacanciesPage/${page}`
    );
  };

  const vacanciesContent = useMemo(() => {
    if (isLoading) {
      return <Spin size="large" />;
    }

    if (!VacanciesnFromDB || VacanciesnFromDB.length === 0) {
      return <div>Not found</div>;
    }

    return (
      <>
        {VacanciesnFromDB.map((vacancy, index) => (
          <VacancyCard vacancy={vacancy} key={index} />
        ))}
        <Pagination
          showSizeChanger={false}
          onChange={handlePageChange}
          current={currentPage}
          pageSize={ITEMS_PER_PAGE}
          total={ammountOfVacancies.totalCount}
        />
      </>
    );
  }, [isLoading, VacanciesnFromDB]);

  return <div>{vacanciesContent}</div>;
};

export default ListVacancies;
